export default {
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
  },
  variants: {
    // TODO: Get rid of this outdated code, leave it just for backward compatibility
    ghost: {
      borderRadius: "50px",
      _hover: {
        bg: "transparent",
      },
      _active: {
        bg: "transparent",
        border: "1px solid transparent",
      },
      _focus: {
        bg: "transparent",
        border: "none",
      },
    },
    purpleVariant: {
      bgGradient: "linear(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)",
      borderRadius: "50px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      color: "#fff",
      filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))",
      _hover: {
        _disabled: {
          color: "#fff",
          bgGradient:
            "linear-gradient(136.13deg, #A78CFF -93.1%, #BF14DE 125.01%)",
        },
        color: "#fff",
        bgGradient:
          "linear-gradient(136.13deg, #A78CFF -93.1%, #BF14DE 125.01%)",
      },
    },
    purpleGhostVariant: {
      bg: "#fff",
      color: "#9536A6",
      border: "1px solid #9536A6",
      borderRadius: "40px",
      _hover: {
        borderColor: "#BF14DE",
        color: "#BF14DE",
      },
    },
    goldVariant: {
      bgGradient: "linear-gradient(99.36deg, #EFA537 -39.65%, #F8DB68 71.68%)",
      borderRadius: "40px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      _hover: {
        bgGradient:
          "linear-gradient(99.36deg, #B84200 -39.65%, #F8AD68 71.68%)",
        color: "000",
      },
    },
    playButtonVariant: {
      color: "#fff",
      bg: "#A6B5E2",
      borderRadius: "40px",
      fontSize: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      _hover: {
        bgGradient:
          "linear-gradient(136.13deg, #A78CFF -93.1%, #BF14DE 125.01%);",
      },
    },
    grayButtonVariant: {
      color: "#fff",
      bg: "#828282",
      borderRadius: "40px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      _hover: {
        _disabled: {
          background: "#828282",
        },
      },
    },
    blueButtonVariant: {
      color: "#fff",
      bg: "#2F80ED",
      borderRadius: "40px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },

    redVariant: {
      bg: "#EB5757",
      borderRadius: "40px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      color: "#fff",
      _hover: {
        bg: "#C73333",
      },
    },

    // TODO: End of outdated variants (delete when migrate)
    // NEW VARIANTS FOLLOWING DESIGN SYSTEM (ref for single source of truth)
    // https://www.figma.com/file/BOoOFuHaWX7YUcxJj0HuAz/MVP-Product?node-id=7995%3A11681&t=uKJIEatKZJ1xLDC3-0
    purple: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 30px",
      background: "linear-gradient(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        background:
          "linear-gradient(136.13deg, #A78CFF -93.1%, #BF14DE 125.01%)",
        _disabled: {
          background:
            "linear-gradient(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)",
        },
      },
      _disabled: {
        background:
          "linear-gradient(136.13deg, #4B21D6 -93.1%, #9536A6 125.01%)",
      },
    },
    yellow: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#000000",
      padding: "10px 30px",
      background: "linear-gradient(99.36deg, #EFA537 -39.65%, #F8DB68 71.68%)",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        bgGradient:
          "linear-gradient(99.36deg, #B84200 -39.65%, #F8AD68 71.68%)",
        color: "#000",
      },
      _disabled: {
        background:
          "linear-gradient(99.36deg, #EFA537 -39.65%, #F8DB68 71.68%) !important",
        color: "#000000 !important",
      },
    },
    red: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 30px",
      background: "#EB5757",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        background: "#C73333",
        _disabled: {
          background: "#EB5757",
        },
      },
      _disabled: {
        background: "#EB5757",
      },
    },
    link: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#5F27CB",
      background: "transparent",
      transition: ".5s",
      _hover: {
        color: "#A027CB",
        textDecoration: "underline",
      },
      _disabled: {
        color: "#CFD7D6",
        textDecoration: "none",
      },
    },
    text: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#6A6A6A",
      padding: "10px 30px",
      background: "transparent",
      transition: ".5s",
      _hover: {
        color: "#0F0F0F",
        textDecoration: "none",
      },
      _disabled: {
        color: "#CFD7D6",
      },
    },
    outline: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
      color: "#9536A6",
      padding: "5px 15px",
      background: "transparent",
      border: "1px solid",
      borderColor: "#9536A6",
      borderRadius: "20px",
      transition: ".5s",
      _hover: {
        background: "transparent",
        color: "#9536A6",
        borderColor: "#9536A6",
        opacity: 0.6,
      },
      _disabled: {
        opacity: 0.6,
      },
    },
    lightblue: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 30px",
      background: "#4B88E4",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        background: "#346DC2",
        _disabled: {
          background: "#346DC2",
        },
      },
      _disabled: {
        background: "#346DC2",
      },
    },
    blue: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 30px",
      background: "#1033A5",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        background: "#4B88E4",
      },
    },
    green: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
      padding: "10px 30px",
      background: "linear-gradient(136.13deg, #1F8F3F -93.1%, #37C857 125.01%)",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "40px",
      transition: ".5s",
      _hover: {
        background:
          "linear-gradient(136.13deg, #4AE375 -93.1%, #56F98B 125.01%)",
        _disabled: {
          background:
            "linear-gradient(136.13deg, #1F8F3F -93.1%, #37C857 125.01%)",
        },
      },
      _disabled: {
        background:
          "linear-gradient(136.13deg, #1F8F3F -93.1%, #37C857 125.01%)",
      },
    },
  },
};
