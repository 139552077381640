import React from "react";
import { FC } from "react";
import createContextSet from "src/utils/createContextSet";
import { useForm, UseFormSetValue } from "react-hook-form";
import { StringOrNull } from "src/types";
import dayjs from "dayjs";
import useFormatDateInMyTimeZone from "src/hooks/useFormatDateInMyTimeZone";

export enum FilterTypes {
  START_DATE = "startDate",
  END_DATE = "endDate",
  ACTION = "action",
  OBJECT_TYPE = "objectType",
}

const initialValues = {
  startDateFilter: null as Date | null,
  endDateFilter: null as Date | null,
  objectTypeFilter: null as StringOrNull,
  actionFilter: null as StringOrNull,
};

type FormValuesType = typeof initialValues;

export type ChangeLogFilterReturnType = {
  startDateUnixTime: number | null;
  endDateUnixTime: number | null;
  startDateFilter: Date | null;
  endDateFilter: Date | null;
  objectTypeFilter: StringOrNull;
  actionFilter: StringOrNull;
  hasActiveFilter: boolean;
  setValue: UseFormSetValue<{
    startDateFilter: Date | null;
    endDateFilter: Date | null;
    objectTypeFilter: StringOrNull;
    actionFilter: StringOrNull;
  }>;
  clearFilterByType: (filterType: FilterTypes) => void;
  setMultipleValues: (newValues: Partial<FormValuesType>) => void;
};

export const changeLogObjectTypes = {
  ACCOUNT: "Account",
  CONTACT: "Contact",
  FAQ: "FAQ",
  SUBSCRIPTION: "Subscription",
};

export const actionObjectType = {
  ADD: "add",
  EDIT: "edit",
  UPDATE: "update",
};

export type ChangeLogContextReturnType = {
  setValue: UseFormSetValue<Omit<ChangeLogFilterReturnType, "hasActiveFilter">>;
};

const [useChangeLogsFilterCtx, ChangeLogsCtxProvider] =
  createContextSet<ChangeLogFilterReturnType>();

const ChangeLogsFilterContext: FC = ({ children }) => {
  const hookFormVals = useForm<FormValuesType>({
    defaultValues: initialValues,
  });

  const clearFilterByType = (filterType: FilterTypes) => {
    if (filterType === FilterTypes.OBJECT_TYPE) {
      setValue("objectTypeFilter", null);
    }
    if (filterType === FilterTypes.START_DATE) {
      setValue("startDateFilter", null);
    }
    if (filterType === FilterTypes.END_DATE) {
      setValue("endDateFilter", null);
    }

    if (filterType === FilterTypes.ACTION) {
      setValue("actionFilter", null);
    }
  };

  const { watch, setValue, reset, getValues } = hookFormVals;
  const startDateFilter = watch("startDateFilter");
  const endDateFilter = watch("endDateFilter");
  const objectTypeFilter = watch("objectTypeFilter");
  const actionFilter = watch("actionFilter");

  const { getUtcFromDate } = useFormatDateInMyTimeZone();
  const startDateUnixTime = startDateFilter
    ? getUtcFromDate(dayjs(startDateFilter).startOf("day"))
    : null;
  const endDateUnixTime = endDateFilter
    ? getUtcFromDate(dayjs(endDateFilter).endOf("day"))
    : null;

  const hasActiveFilter = !![
    startDateFilter,
    endDateFilter,
    actionFilter,
    objectTypeFilter,
  ].find((item) => Boolean(item));

  const setMultipleValues = (newValues: Partial<FormValuesType>) => {
    const prevVals = getValues();
    reset({
      ...prevVals,
      ...newValues,
    });
  };

  const contextValue = React.useMemo(
    () => ({
      startDateUnixTime,
      endDateUnixTime,
      startDateFilter,
      endDateFilter,
      objectTypeFilter,
      actionFilter,
      hasActiveFilter,
      setValue,
      clearFilterByType,
      setMultipleValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      startDateUnixTime,
      endDateUnixTime,
      startDateFilter,
      endDateFilter,
      objectTypeFilter,
      actionFilter,
      hasActiveFilter,
      setValue,
      setMultipleValues,
      // clearFilterByType,
    ]
  );

  return (
    <ChangeLogsCtxProvider value={contextValue}>
      {children}
    </ChangeLogsCtxProvider>
  );
};

export default ChangeLogsFilterContext;
export const useChangeLogFilterContext = () => useChangeLogsFilterCtx();
