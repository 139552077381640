import { StringOrNull } from "src/types";
import { useQuery, QueryFunction } from "react-query";
import { useCompanySelection } from "src/store/companySelectionState";
import useUserAccount from "src/store/userAccountState";
import axios from "src/services/network/axios";
import getCompanyInfoKeys, { QueryKeysType } from "./getCompanyInfoKeys";

export type AccountManagerType = {
  title: string;
  name: string;
  id: string;
  firstName: string;
  email: string;
  avatar: StringOrNull;
  active: boolean;
};
type ReceptionistTeamLeadType = {
  avatar: string | undefined;
  name: string;
};

export type SubAccountInfoType = {
  phone: string | null;
  name: string;
  minutesUsedSoFar: number | null;
  minutesRemaining: number | null;
  id: string;
  chatsUsedSoFar: number | null;
  chatsRemaining: number | null;
  chatAllowance: number | null;
  callAllowance: number | null;
  aiMinutesUsedSoFar: number | null;
  aiMinutesRemaining: number | null;
  aiCallAllowance: number | null;
  accountNumber: number | null;
};

export type CompanyInfoType = {
  receptionistTeamLead: ReceptionistTeamLeadType;
  phone: StringOrNull;
  name: string;
  id: string;
  guid: StringOrNull;
  accountType: string;
  accountNumber: string;
  accountManager: AccountManagerType;
  abbyStage: StringOrNull;
  abbyStage2: StringOrNull;
  abbyClosedDate: StringOrNull;
  abbyClosedDateLiveChts: StringOrNull;
  abbyStageLiveChat: StringOrNull;
  status: StringOrNull;
  statusLiveChat: StringOrNull;
  calendlyLink: string;
  type: string;
  authCustProfile: any;
  zapierAPIKey: string;
  eFax: string[];
  forwardingNumbers: string[];
  echeckApproved: boolean;
  calibrationSpecialist?: {
    avatar: string;
    email: string;
    name: string;
  };
  numberOfForms: number;
  aiFormQuestionsLimit: number;
  aiFormBranchesLimit: number;
  aiCallAllowance: number | null;
  aiMinutesRemaining: number | null;
  aiMinutesUsedSoFar: number | null;
  minutesRemaining: number | null;
  minutesUsedSoFar: number | null;
  chatsRemaining: number | null;
  chatsUsedSoFar: number | null;
  aiFormRedirects: string[];
  formQuestionsLimit: number;
  formBranchesLimit: number;
  formRedirects: string[];
  hubSpotOwnerId: string;
  callRecordingType?: string;
  subAccounts: SubAccountInfoType[] | null;
  otherAccounts: SubAccountInfoType[] | null;
  totalMinutesUsed: number | null;
  totalMinutesToBeUsed: number | null;
  totalChatsUsed: number | null;
  totalChatsToBeUsed: number | null;
  totalAIMinutesUsed: number | null;
  totalAIMinutesToBeUsed: number | null;
};
// ** SAMPLE RESPONSE
// {
//   status: null,
//   receptionistTeamLead: null,
//   phone: null,
//   name: "Minh Than 2",
//   id: "0015900000MoTkbAAF",
//   accountType: "Allied Offices",
//   accountNumber: "217315",
//   accountManager: {
//     title: "Center Manager",
//     name: "Corielle Crawford",
//     id: "a005A00000p2OrAQAU",
//     firstName: "Corielle",
//     email: "corielle@alliedoffices.com",
//     avatar: null,
//     active: true,
//   },
// };

const getCompanyInfo: QueryFunction<CompanyInfoType, QueryKeysType> = async ({
  queryKey,
}) => {
  const [companyId, businessAccountId] = queryKey;

  // {{baseUrl}}/businessAccounts/:businessAccountId/companies/:companyId
  const response = await axios.get(
    `/businessAccounts/${businessAccountId}/companies/${companyId}`
  );

  return response?.data;
};

const useCompanyInfo = () => {
  const { userAccount } = useUserAccount();
  const companySelection = useCompanySelection();
  const activeCompany = companySelection!.activeCompany as string;
  const businessAccountId = userAccount?.businessAccountId || "";

  const queryKey = getCompanyInfoKeys(activeCompany, businessAccountId);

  const query = useQuery({
    queryKey,
    queryFn: getCompanyInfo,
    enabled: !!businessAccountId,
  });

  return { ...query, queryKey };
};

export default useCompanyInfo;
