import {
  AccountInformationType,
  useAccountSettingState,
} from "src/store/accountSettingState";
import dayjs, { Dayjs } from "dayjs";
import TimeZone from "src/utils/timezone";
import { useCallback, useEffect, useState } from "react";

const useFormatDateInMyTimeZone = () => {
  const [timeZone, setTimeZone] = useState<typeof TimeZone[number]>();
  const { accountInformation } = useAccountSettingState();
  let { timeZone: userTimeZone } =
    accountInformation || ({} as AccountInformationType);

  const getTimeZone = useCallback(() => {
    if ("(GMT-07:00) Arizona" === userTimeZone) {
      // GMT-07:00 isn't recognized by dayjs
      userTimeZone = "(UTC-07:00) Arizona";
    }
    if (userTimeZone) {
      const timeZoneObj = TimeZone.find((tz) => tz.text === userTimeZone);
      setTimeZone(timeZoneObj);
    }
  }, [userTimeZone]);

  useEffect(() => {
    getTimeZone();
  }, [getTimeZone]);

  const getMyTimeZoneString = (date: Dayjs = dayjs()) => {
    if (!timeZone) return "UTC";

    return timeZone.text.replace(/\(.*?\)/g, "").trim();
    // return dayjs.tz(date, timeZone?.utc[0]).format("zzz"); // IANNA format
    // return timeZone.text.split(") ")[1]; // same data that coming from UI
  };

  const getMyTimeZoneAbbrString = (date: Dayjs = dayjs()) => {
    if (!timeZone) return "UTC";

    return dayjs.tz(date, timeZone?.utc[0]).format("z");
    // return timeZone?.abbr || "UTC"; // alternatively, but above is preferred since we will use standard one and taking count of DST
  };

  const formatDateStringInMyTimeZone = (
    dateStr: string | null,
    showTimeZone = true
  ) => {
    if (!dateStr || !timeZone) return null;

    return (
      dayjs(dateStr)
        .tz(timeZone?.utc[0] || "UTC")
        .format("MM/DD/YYYY h:mm A") +
      (showTimeZone ? ` ${getMyTimeZoneString()}` : "")
    );
  };

  const formatDateObjInMyTimeZone = (
    dateObj: Dayjs | null,
    format = "MM/DD/YYYY h:mm A",
    showTimeZone = true
  ) => {
    if (!dateObj || !timeZone) return null;

    return (
      dayjs(dateObj)
        .tz(timeZone?.utc[0] || "UTC")
        .format(format) + (showTimeZone ? ` ${getMyTimeZoneString()}` : "")
    );
  };

  const formatDateObjInMyTimeZoneForInput = (
    dateObj: Dayjs | null,
    format = "YYYY-MM-DD HH:mm"
  ) => {
    if (!dateObj || !timeZone || !timeZone?.utc || !timeZone?.utc[0]) {
      return null;
    }

    return dayjs.tz(dayjs(dateObj), timeZone.utc[0]).format(format);
  };

  const moveDateToMyTimeZone = (dateStr: string | null) => {
    if (!dateStr || !timeZone || !timeZone?.utc || !timeZone?.utc[0])
      return null;

    return dayjs(dateStr).tz(timeZone?.utc[0] || "UTC", true);
  };

  const moveTimestampToMyTimeZone = (timestamp: number | null) => {
    if (!timestamp || !timeZone || !timeZone?.utc || !timeZone?.utc[0]) {
      return null;
    }

    return dayjs(timestamp * 1000).tz(timeZone?.utc[0] || "UTC", true);
  };

  const convertLocalTimestampToUtc = (localDate: Dayjs | null) => {
    if (!localDate) {
      return null;
    }

    if (!timeZone || !timeZone?.utc || !timeZone?.utc[0]) {
      return localDate.unix();
    }

    return localDate.tz(timeZone?.utc[0] || "UTC", true).unix();
  };

  const updateTimeOnDate = (
    sourceDate: Dayjs | null,
    hours: number,
    minutes: number
  ) => {
    if (!sourceDate) {
      return null;
    }

    return sourceDate
      .tz("America/Chicago")
      .set("hours", hours)
      .set("minutes", minutes)
      .unix();
  };

  const updateDateInMyTimezone = (
    sourceTime: number | null,
    newDateVal: string | null
  ) => {
    if (!sourceTime || !newDateVal) {
      return null;
    }

    const currSouceTime = dayjs
      .utc(sourceTime * 1000)
      .tz(timeZone?.utc[0] || "UTC");
    return dayjs
      .utc(newDateVal)
      .tz(timeZone?.utc[0] || "UTC", true)
      .hour(currSouceTime.hour())
      .minute(currSouceTime.minute())
      .second(0);
  };

  const getUtcFromDate = (sourceDate: Dayjs | null) => {
    if (!sourceDate) {
      return null;
    }

    return dayjs
      .utc(dayjs(sourceDate).utc(true))
      .tz(timeZone?.utc[0] || "UTC", true)
      .unix();
  };

  return {
    formatDateStringInMyTimeZone,
    formatDateObjInMyTimeZone,
    formatDateObjInMyTimeZoneForInput,
    getMyTimeZoneString,
    getMyTimeZoneAbbrString,
    moveDateToMyTimeZone,
    moveTimestampToMyTimeZone,
    convertLocalTimestampToUtc,
    updateTimeOnDate,
    updateDateInMyTimezone,
    getUtcFromDate,
  };
};

export default useFormatDateInMyTimeZone;
