const enum ActivityQueryTypes {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
  SMS = "sms",
  LIVE_CHAT = "livechat",
  ALL = "",
  MESSAGES = "receptionistmessage",
  VOICEMAIL = "voicemailmessage",
}

export const activityQueryVals = {
  INCOMING: "incoming",
  OUTGOING: "outgoing",
  SMS: "sms",
  LIVE_CHAT: "livechat",
  ALL: "",
  MESSAGES: "receptionistmessage",
  VOICEMAIL: "voicemailmessage",
};

export default ActivityQueryTypes;
